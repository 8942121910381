.text-btn {
    cursor: pointer;
    color: #007bff
}

text-btn:hover {
    color: #0056b3
}

.tag svg {
    cursor: pointer;
    margin-bottom: -1px;
    margin-left: 5px;
}

button.tag {
    cursor: default !important;
}

.list-item-title{
    font-size: 1.1em;
}

.toast-alert {
    position: fixed;
    top: 0;
    z-index: 10001;
    margin-top: 10px;
}
