header{
    height: 75px;
}

.mobile-logo {
    max-height: 40px;
}

.desktop-logo{
    max-height: 70px;
}

.mobile-header {
    box-shadow: 2px 2px 2px 1px rgba(168, 179, 200, 0.34)!important;
}

.dropdown-toggle.nav-link {
    color: #343434;
    font-size: 1em;
    padding-left: 22px!important;
}

.dropdown-toggle.nav-link:hover {
    color: #343434;
}

.dropdown-toggle.nav-link:after {
    content: none
}

.dropdown-item{
    font-size: 1em;
    color:#343434;
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lbt-aux{
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 34px;
}

#doctor-menu-dropdown .dropdown-menu{
    top:120%!important;
    float: right !important;
    max-width: 500px!important;
    min-width: 250px!important;
    right: -12px;
    left: auto;

}
