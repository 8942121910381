#nav-dropdown{
	padding-bottom: 0px;
}

#pharma-resume{
	display: block;
}

#breadcrumb-row{
	display: block;
}

.mobile-NavRow {
	background-color: #f5f7fb;
}