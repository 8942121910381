h5.pointer {
    cursor: pointer;
}

.primary-section-header{
    color: #000000;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: none!important;
}


.primary-section-body{
    color: #5b5b5b;
    font-size: 1em;
    margin-left: 1rem;
    margin-right: 1rem;
}

.survey-section{
    background-color:white!important;
    border-top: 1px solid rgba(0,0,0,.125)!important;
    border-bottom: none!important;
    border-left: none!important;
    border-right: none!important;
}

.secondary-section{
    background-color:white!important;
    border:none!important;
}

.secondary-section-header{
    color: #9165ca!important;
    background-color: white!important;
    font-size: 1.3em!important;
    font-weight: 400!important;
    text-transform: uppercase!important;
    border-bottom: solid 1px  #9165ca !important;
    padding-right: 3rem!important;
}



.secondary-section-body{
    color: #5b5b5b!important;
    font-size: 1em!important;

}

.primary-subsection{
    color: #000000;
    font-size: 1.2em;
    font-weight: 400;
}

.primary-subsection-subtitle{
    text-transform: uppercase;
}


.secondary-subsection{
    color: #9165ca;
    font-size: 1.2em;
    font-weight: 400;
}


.no-border{
    border: none!important;
    box-shadow: none;
    border-radius: 0;
}

.rounded{
    border-radius:0.5rem!important;
}

.interactions-section-header{
    display: flex;
    justify-content: space-between;
    color: #FFce43;
    font-size: 1.3em;
    font-weight: 400;
}
