.sentiment-google > h1 {
    color: rgb(144,237,125);
    font-size: 10vh;
}

.sentiment-google input {
    width: 70%;
    margin: auto;
}

.sentiment-google .faces-range {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.protocol-title{
    padding-top: 15px;
}
.protocol-title span{
    font-size: 24px!important;
}
