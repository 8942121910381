.pop-up-alert {
    position: fixed;
    top: 0;
    z-index: 10001;
    margin-top: 10px;
    width: 45%;
    left: 30%;
}

.pop-up-alert div.alert {
    margin: 0 auto;
    width: fit-content;
    font-size: unset;
}
